const appConfig = {
  servicePrefix: {
    local: {
      app: "http://localhost:300",
      district: "https://apiv2.quamuslms.id/district",
    },
    prod: {
      app: "https://api.imagi.id/app",
      district: "https://apiv2.quamuslms.id/district",
    },
  },
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "en",
  enableMock: false,
  stage: "prod",
};

export default appConfig;

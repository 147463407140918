import appConfig from "configs/app.config";
import ApiService from "services/ApiService";


export default class Index {
  static async login(data) {
    return ApiService.fetchData({
      url: `${appConfig.servicePrefix[appConfig.stage].app}/user/login`,
      method: "post",
      data,
    });
}
}

import PopNotification from "components/shared/PopNotification";
import printJS from "print-js";
import moment from "moment";

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const generateTimeString = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hour = now.getHours();
  const minute = now.getMinutes();
  const second = now.getSeconds();

  const timeString = `${year}${month}${day}${hour}${minute}${second}`;
  return timeString;
};

export const downloadDoc = async (response, type, fileName = "data") => {
  try {
    const blobType = {
      pdf: "application/pdf",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      txt: "text/plain",
      zip: "application/zip",
      xls: "application/vnd.ms-excel", // Untuk file Excel 97-2003
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      doc: "application/msword", // Untuk file Word 97-2003
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ppt: "application/vnd.ms-powerpoint", // Untuk file PowerPoint 97-2003
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      mp4: "video/mp4",
      avi: "video/x-msvideo",
      mov: "video/quicktime",
      other: "application/octet-stream", // Default untuk tipe file lain yang tidak dikenali
    };

    const blob = new Blob([response.data], {
      type: blobType[type],
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}_${generateTimeString()}.${type}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (e) {
    // console.log(e);
    PopNotification("danger", "error", "Download File gagal.");
  }
};

export const downloadDocFromUrl = async (response, type, fileName = "data") => {
  try {
    // Define the MIME types for the file types
    const blobType = {
      pdf: "application/pdf",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      txt: "text/plain",
      zip: "application/zip",
      xls: "application/vnd.ms-excel",
      jpg: "image/jpeg",
      jpeg: "image/jpeg",
      png: "image/png",
      doc: "application/msword",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ppt: "application/vnd.ms-powerpoint",
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      mp4: "video/mp4",
      avi: "video/x-msvideo",
      mov: "video/quicktime",
      other: "application/octet-stream",
    };

    // Fetch the file data from the URL
    const responseData = await fetch(response.data);

    if (!responseData.ok) {
      throw new Error("Network response was not ok.");
    }

    // Convert response to Blob
    const blob = await responseData.blob();

    // Use Blob type if specified
    const mimeType = blobType[type] || blobType["other"];

    // Create a new Blob with the correct type
    const blobWithType = new Blob([blob], { type: mimeType });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blobWithType);

    // Create a download link
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}_${generateTimeString()}.${type}`;
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (e) {
    console.error(e);
    PopNotification("danger", "error", "Download File gagal.");
  }
};

export const printDoc = async (response, setIsPrinting) => {
  try {
    const blob = new Blob([response.data], {
      type: "application/pdf",
      endings: "native",
    });
    blob.filename = "nama_file.pdf";

    const uri = URL.createObjectURL(blob);
    printJS({
      printable: uri,
      type: "pdf",
      onPrintDialogClose: function () {
        setIsPrinting(false);
      },
      onError: (e) => {
        // console.log(e);
      },
    });
    URL.revokeObjectURL(uri);
  } catch (e) {
    PopNotification("danger", "error", e);
  }
};

export const printReceipt = () => {
  const iframe = document.createElement("iframe");
  iframe.src = `/report/receipt.html`;
  iframe.style.width = "0";
  iframe.style.height = "0";

  document.body.appendChild(iframe);

  return () => {
    if (iframe.parentNode) {
      iframe.parentNode.removeChild(iframe);
    }
  };
};

export const getAvailableDateRange = (currentDate) => {
  const current = moment(currentDate);
  const closingDayThisMonth = moment(current).date(5);
  const closingDayLastMonth = moment(current).subtract(1, "month").date(5);

  let minDate, maxDate;

  if (current.isSameOrBefore(closingDayThisMonth)) {
    minDate = moment(current).subtract(1, "month").startOf("month");
    maxDate = closingDayThisMonth;
  } else {
    minDate = moment(current).startOf("month");
    maxDate = moment(current).add(1, "month").date(5);
  }

  return {
    minDate: minDate.format("YYYY-MM-DD"),
    maxDate: maxDate.format("YYYY-MM-DD"),
  };
};

export const intersectArray = (
  arr1,
  identifier1,
  arr2,
  identifier2,
  type = "outer"
) => {
  let arr = [];
  const arr2_id = arr2.map((item) => item[identifier2]);
  arr1.map((i) => {
    if (
      arr2_id.includes(i[identifier1]) === (type === "outer" ? false : true)
    ) {
      arr.push(i);
    }
  });
  return arr;
};
